import React from "react";
import styles from './Select.module.scss';


const Select = ( props ) => {

  const key = props.field.form_key;
  const required = props.field.form_required;
  const label = props.field.form_label + (required ? '*' : '');
  const entries = props.field.form_entries;

  const options = entries.map((option, index) => {
    return(
      <option value={option.form_value} key={index}>
        {option.form_title}
      </option>
    );
  });

  return (
    <div className={styles.oSelect}>
      <select id={key}
              name={key}
              required={required}
              className={styles.oSelectField}>
        {options}
      </select>
      <label className={styles.oSelectLabel} htmlFor={key}>
        {label}
      </label>
    </div>
  );
};
export default Select;
