import React, {useState, useEffect} from "react";

import styles from './../LinkBoxes/LinkBoxes.module.scss';
import LinkBox from "./../LinkBoxes/LinkBox";
import {fetchCourse} from "../../util/helpers";
import {coursesActions} from "../../stores/reducers/courses";
import {useDispatch, useSelector} from "react-redux";


const CourseOverviewGrid = ( props ) => {

  const [courses, setCourses] = useState(undefined);
  const dispatch = useDispatch();
  const storedCourses = useSelector(state => state.courses.courses);
  const activeFilter = useSelector( state => state.filter.active );

  const filter_for   = props.attrs.data['block_courses-overview_for'] ?? [];
  const filter_where = props.attrs.data['block_courses-overview_where'] ?? [];
  const filter_when  = props.attrs.data['block_courses-overview_when'] ?? [];

  const additional = props.attrs.data['block_courses-overview_additional_courses'] ?? [];

  const usingFilter = filter_for.length === 0 && filter_when.length === 0 && filter_where.length === 0;

  useEffect( () => {
    if(typeof courses === 'undefined') {
      fetchCourse().then( data => {
        setCourses(data.courses);
        for(let i = 0; i < data.courses.length; i++) {
          if( data.courses[i] in storedCourses ) {
            dispatch(coursesActions.addCourse({
              'id': data.courses[i].id,
              'course': data.courses[i]
            }));
          }
        }
      } ).catch( error => {
        console.log(error)
      } );
    }
  }, [setCourses, courses, dispatch, storedCourses]);



  let linkBoxes = '';
  let linkBoxWrapper = '';

  if(typeof courses !== 'undefined' && courses.length > 0) {
    linkBoxes = courses.filter(function(course) {

      let showCourse = true;

      if( additional.length > 0 && additional.includes( `${course.id}` ) ) {
        return true;
      }

      // IF it is the overview used for the filter
      if(usingFilter) {
        if(activeFilter.cat_for && activeFilter.cat_for.length > 0 && course.categories['for']) {
          showCourse = course.categories['for'].some(term => {
            return activeFilter.cat_for.includes(term.term_id);
          });
        }
        if(showCourse && activeFilter.cat_when && activeFilter.cat_when.length > 0 && course.categories['when']) {
          showCourse = course.categories['when'].some(term => {
            return activeFilter.cat_when.includes(term.term_id);
          });
        }
        if(showCourse && activeFilter.cat_where && activeFilter.cat_where.length > 0 && course.categories['where']) {
          showCourse = course.categories['where'].some(term => {
            return activeFilter.cat_where.includes(term.term_id);
          });
        }
      }
      // otherwise, the filter is retrieved by the element itself
      else {
        if(filter_for.length > 0 && course.categories['for']) {
          showCourse = course.categories['for'].some(term => {
            return filter_for.includes(term.term_id.toString());
          });
        }
        if(filter_when.length > 0 && course.categories['when']) {
          showCourse = course.categories['when'].some(term => {
            return filter_when.includes(term.term_id.toString());
          });
        }
        if(filter_where.length > 0 && course.categories['where']) {
          showCourse = course.categories['where'].some(term => {
            return filter_where.includes(term.term_id.toString());
          });
        }
      }

      return showCourse;

    }).map(function(course, index) {
      let item = {
        'title':    course.title,
        'image':    course.thumbnail,
        'link':     course.permalink,
        'title_bg': false,
        'icon':     false,
        'layout':   index % 6 === 1 || index % 6 === 3 ? '2x2' : '2x1',
        'borderStyle': course.border.border_style,
        'borderWidth': course.border.border_width,
        'isCourse': true,
        'course': course.id,
      };
      return <LinkBox key={index} LinkBox={item}></LinkBox>
    });

    if(linkBoxes.length === 0) {
      linkBoxWrapper = (<div className={styles.noResults}>Es wurden keine Ergebnisse gefunden.</div>) ;
    }
    else {
      linkBoxWrapper = (
        <div className={styles.linkBoxes}>
          {linkBoxes}
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {linkBoxWrapper}
    </React.Fragment>
  );
};
export default CourseOverviewGrid;
