import React from "react";

import styles from './CourseOverview.module.scss';
import {useSelector} from "react-redux";
import classNames from "classnames/bind";
import {ReactComponent as SoldOut} from "../../assets/images/icons/Info.svg";

const classes = classNames.bind( styles );

const ListItem = ( props ) => {

  const item = props.item;

  const courses = useSelector(state => state.courses.courses);
  const course = courses[item.course];

  if(!courses || !(item.course in courses)) {
    return '';
  }

  let itemWhen = course.date;

  return (
    <a className={classes({
      'listItem': true,
      'is_past': course.is_past,
    })} href={item.link}>
      <div className={styles.itemName}>
        <div>
          { course.sold_out ? <div className={styles.soldOutHolder}><SoldOut className={styles.soldOutIcon} /></div> : ''}
        </div>
        <div>{item.title}</div>
      </div>

      <div className={styles.itemWhen}> {itemWhen} </div>

    </a>
  );
};
export default ListItem;
