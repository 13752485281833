import React from "react";
import styles from './Upload.module.scss';


const Upload = ( props ) => {

  const key = props.field.form_key;
  const required = props.field.form_required;
  const label = props.field.form_label + (required ? '*' : '');
  const accept = props.field.form_allowed_formats;
  const multiple = props.field.form_multiple;

  return (
    <div className={styles.oUpload}>
      <input type={'file'}
             name={key}
             required={required}
             className={styles.oUploadField}
             accept={accept}
             multiple={multiple}
      />
      <label className={styles.oUploadLabel} htmlFor={key}>
        {label}
      </label>
    </div>
  );
};
export default Upload;
