import React, {useEffect, useState} from "react";
import styles from './Filter.module.scss';
import Filter from "./Filter";
import {useSelector} from "react-redux";
import classNames from "classnames/bind";

import { ReactComponent as ArrowIcon } from './../../assets/images/icons/ArrowRound.svg';

const classes = classNames.bind( styles );


const FilterButton = ( props ) => {

  const current_page = useSelector( state => state.page.currentPage );
  const courses_page = useSelector(state => state.theme.options.courses_page);
  const [ showFilter, setShowFilter ] = useState( false );

  useEffect( () => {
    if(typeof courses_page !== 'undefined' && typeof current_page !== 'undefined') {
      if((courses_page && current_page && courses_page.id === current_page.id)) {
        setShowFilter(true);
      }
    }
  }, [current_page] );

  return (
    <div className={styles.filterButtonHolder}>
      <div className={styles.filterButton} onClick={() => { setShowFilter( !showFilter ) }}>
        <ArrowIcon className={classes({
          arrow: true,
          showFilter: showFilter
        })} />
        Angebotssuche
      </div>

      <Filter showFilter={showFilter} />

    </div>
  );
};
export default FilterButton;
