import React from "react";
import styles from './Radio.module.scss';
import classNames from "classnames/bind";
const classes = classNames.bind( styles );

const Radio = ( props ) => {

  const key = props.field.form_key;
  const required = props.field.form_required;
  const label = props.field.form_label + (required && props.field.form_label !== '' ? '*' : '');

  const options = props.field.form_entries.map((option, index) => {
    return (
      <div className={styles.oRadio} key={index}>
        <input type="radio"
               id={option.form_value}
               name={key}
               value={option.form_value}
               className={styles.oRadioField}/>
        <label className={styles.oRadioLabel} htmlFor={option.form_value}>
          {option.form_title}
        </label>
      </div>
    );
  })

  return (
    <React.Fragment>
      <div className={classes({
        'oRadioButtons': true,
        'isVertical': props.field.form_layout === 'vertical'
      })}>
        {options}
      </div>
      <label className={styles.oRadioName} >
        {label}
      </label>
    </React.Fragment>
  );
};
export default Radio;
