import React from "react";
import styles from './CheckboxGroup.module.scss';
import checkboxStyles from '../Checkbox/Checkbox.module.scss';


const CheckboxGroup = ( props ) => {

  const key = props.field.form_key;
  const required = props.field.form_required;

  const options = props.field.form_entries.map((option, index) => {
    return (
      <div className={checkboxStyles.oCheckbox} key={index}>
        <input type={'checkbox'}
               required={required}
               id={option.form_value}
               name={key+'[]'}
               value={option.form_value}
               className={checkboxStyles.oCheckboxField}/>
        <label className={checkboxStyles.oCheckboxLabel} htmlFor={option.form_value}>
          {option.form_title}
        </label>
      </div>
    );
  })

  return (
    <React.Fragment>
      <div className={styles.oRadioButtons}>
        {options}
      </div>
    </React.Fragment>
  );
};
export default CheckboxGroup;
