import React from "react";
import styles from './Text.module.scss';


const Text = ( props ) => {

  const text = props.attrs.data['block_text_text'];

  return (
    <div className={styles.text}
         dangerouslySetInnerHTML={{__html: text}} >
    </div>
  );

};
export default Text;
