 import React, {useState, useEffect} from "react";

import PostTile from "./PostTile";
import styles from './BlogOverview.module.scss';
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import classNames from "classnames/bind";
import {ReactComponent as Next} from "../../assets/images/icons/Pfeil_rechts.svg";
import {ReactComponent as Prev} from "../../assets/images/icons/Pfeil_links.svg";

import {fetchPosts} from "../../util/helpers";


const classes = classNames.bind( styles );


const BlogOverview = ( props ) => {

  const [ posts, setPosts ] = useState( undefined );
  const [ isLoading, setIsLoading ] = useState( false );
  const selection = props.attrs.data['block_blog-overview_selection'];
  let selected_ids = '';
  const [ page, setPage ] = useState( 1 );
  const [ pages, setPages ] = useState( 0 );
  const ppp = 8;

  if(selection === 'select') {
    selected_ids = '&p=' + JSON.stringify(props.attrs.data['block_blog-overview_posts']);
  }


  useEffect( () => {
    if(typeof posts === 'undefined') {
      fetchPosts(page, selected_ids, ppp).then( data => {
        setPosts(data.posts);
        setPages(Math.ceil(data.found_posts / ppp));
      } );
    }
  });


  let loadPage = (page) => {
    setIsLoading(true);
    fetchPosts(page, selected_ids, ppp).then( data => {
      setPosts(data.posts);
      setIsLoading(false);
      setPage(page);
    } ).catch( error => {
      setIsLoading(false);
      console.log(error)
    } );
  }


  let blogPosts = '';
  if(typeof posts !== 'undefined') {
    blogPosts = posts.map((post, index) =>
      <Column width={6} breakpoints={{ m: 12 }} key={post.id} className={styles.blogCol}>
        <PostTile
          post={post}
          ltf={index % 4 === 1 || index % 4 === 2}
        >
        </PostTile>
      </Column>
    );
  }

  let pagination = [];
  if(pages > 1) {
    pagination.push( <Prev key={"prev"} className={classes({
      'prev': true,
      'disabled': page === 1
    })} onClick={() => {loadPage( page - 1)}}/> )

    for(let i = 1; i <= pages; i++) {
      pagination.push( <div key={i} className={classes({
        'page': true,
        'active': page === i
      })} onClick={() => loadPage(i)}>{i}</div> );
    }

    pagination.push( <Next key={"next"} className={classes({
      'next': true,
      'disabled': page >= pages
    })} onClick={() => {loadPage( page + 1)}}/> )
  }


  return (
    <React.Fragment>

      <div className={classes({
        'posts': true,
        'isLoading': isLoading
      })}>
        <Row>
          {blogPosts}
        </Row>
      </div>

      <div className={styles.pagination}>
        {pagination}
      </div>

    </React.Fragment>
  );
};
export default BlogOverview;
