import React from "react";

import styles from './PostTile.module.scss';
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import classNames from "classnames/bind";
const classes = classNames.bind( styles );


const PostTile = ( props ) => {

  const post = props.post;
  const ltf = props.ltf;

  return (
    <a href={post.permalink} className={styles.post}>
      <Row className={ltf ? styles.postLTR : ''}>
        <Column width={6} className={styles.contentCol}>
          <div className={styles.imageHolder}>
            { post.thumbnail ? (
              <img src={post.thumbnail['sizes']['full']} alt={post.thumbnail['alt']} title={post.thumbnail['title']} className={styles.image} />
            ) : '' }
            <div className={classes({
              'imageBorder': true,
              'dashed': post.border.border_style === 'dashed',
              'normalBorder': post.border.border_width === 'normal',
              'thinBorder': post.border.border_width === 'thin',
            })}></div>
          </div>
        </Column>

        <Column width={6} className={styles.contentCol}>
          <div className={styles.content}>
            <div className={styles.date}>{post.date}</div>
            <div className={styles.title}>{post.title}</div>
            <div className={styles.excerpt}>{post.excerpt}</div>
            <div className={styles.button}>{"mehr"}</div>
          </div>
        </Column>
      </Row>
    </a>
  );
};
export default PostTile;
