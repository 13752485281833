import React from "react";
import styles from './Textfield.module.scss';


const Textfield = ( props ) => {

  const type = props.type;
  const placeholder = props.field.form_placeholder;
  const key = props.field.form_key;
  const required = props.field.form_required;
  const label = props.field.form_label + (required ? '*' : '');

  return (
    <div className={styles.oInput}>
      <input type={type}
             id={key}
             name={key}
             required={required}
             placeholder={placeholder}
             className={styles.oInputField} />
      <label className={styles.oInputLabel}>{label}</label>
    </div>
  );
};
export default Textfield;
