import React, {useRef, useCallback} from "react";
import styles from './Slider.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import {ReactComponent as Prev} from "../../assets/images/icons/Pfeil_links.svg";
import {ReactComponent as Next} from "../../assets/images/icons/Pfeil_rechts.svg";


const Slider = ( props ) => {

  let images = [];
  let slides = [];

  if(typeof props.attrs !== 'undefined') {
    for(let i = 0; i < props.attrs.data['block_slider_images']; i++) {
      images.push(props.attrs.data['block_slider_images_' + i + '_block_slider_img']);
    }
  }
  else {
    images = props.images;
  }

  for(let i = 0; i < images.length; i++) {
    slides[i] = (
      <SwiperSlide key={i} className={styles.slide}>
        <figure className={styles.slideFigure}>
          <div className={styles.imageHolder}>
            <img src={images[i]['sizes']['full']} alt={images[i]['alt']} title={images[i]['title']}
                 className={styles.image}/>
          </div>
          {images[i]['caption'] !== '' && (
            <figcaption>{images[i]['caption']}</figcaption>
          )}
        </figure>
      </SwiperSlide>
    );
  }


  const sliderRef = useRef( null );

  const handlePrev = useCallback( () => {
    if( !sliderRef.current ) return;
    sliderRef.current.swiper.slidePrev();
  }, [] );

  const handleNext = useCallback( () => {
    if( !sliderRef.current ) return;
    sliderRef.current.swiper.slideNext();
  }, []);


  return (
    <div className={styles.sliderWrapper}>
      <Swiper ref={sliderRef}
              className={styles.swiper}
              loop={true}
              slidesPerView={1}>
        {slides}
        <div className={styles.prev} onClick={handlePrev} >
          <Prev/>
        </div>
        <div className={styles.next} onClick={handleNext} >
          <Next/>
        </div>
      </Swiper>
    </div>
  )
};

export default Slider;
