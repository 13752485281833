import React from "react";

import styles from './LinkBoxes.module.scss';
import LinkBox from "./LinkBox";


const LinkBoxes = ( props ) => {

  const nr_items = props.attrs.data['block_link-boxes_link_box'];
  let items = [];

  console.log(props.attrs.data);
  for(let i = 0; i < nr_items; i++) {
    items.push({
      'title':    props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_title'],
      'type':     props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_type'],
      'video':    props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_video'],
      'image':    props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_image'],
      'link':     props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_link'],
      'title_bg': props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_title_bg'] === '1',
      'title_bg_color': props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_title_bg_color'],
      'icon':     props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_icon'],
      'layout':   props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_layout'],
      'dashed':   false,

      'isCourse': props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_is_course'] === '1',
      'course': props.attrs.data['block_link-boxes_link_box_' + i + '_block_link-boxes_course'],
    });
  }

  let linkBoxes = '';
  if(typeof items !== 'undefined' && items.length > 0) {
    linkBoxes = items.map((item, index) =>
      <LinkBox key={index} LinkBox={item}></LinkBox>
    );
  }

  return (
    <div className={styles.linkBoxes}>
      {linkBoxes}
    </div>
  );
};
export default LinkBoxes;
