import React, {useState, useEffect} from "react";

import styles from './News.module.scss';
import {REST_BASE, REST_POSTS} from "../../util/constants";

import { ReactComponent as NewsIcon } from './../../assets/images/icons/News.svg';
import LinkBox from "../LinkBoxes/LinkBox";
import {useSelector} from "react-redux";
import { ReactComponent as Plus } from './../../assets/images/icons/Mehr.svg';


const News = ( props ) => {

  const [ posts, setPosts ] = useState( undefined );
  const news_page = useSelector(state => state.theme.options.news_page);
  const layout = props.attrs.data['block_news_layout'];
  const text = props.attrs.data['block_news_text'];
  const link = props.attrs.data['block_news_link'];
  const ppp = layout === 'four' ? '4' : '3';

  const fetchPosts = async () => {
    const response = await fetch( REST_BASE + REST_POSTS + '?ppp=' + ppp + '&cat=blog' );
    if( !response.ok ) throw new Error('Failed fetching posts data.');
    return await response.json();
  };

  useEffect( () => {
    if(typeof posts === 'undefined') {
      fetchPosts().then( data => {
        setPosts(data.posts);
      } ).catch( error => {
        console.log(error)
      } );
    }
  });

  let blogPosts = '';
  if(typeof posts !== 'undefined') {
    blogPosts = posts.map((post, index) => {
      let link = [];
      link['url'] = post.permalink;
      let item = {
          'title':    post.title,
          'image':    post.thumbnail,
          'link':     link,
          'title_bg': post.title_bg,
          'title_bg_color': post.title_bg_color,
          'icon':     false,
          'layout':   layout === 'four' ? '1x1' : (index === 0 ? '2x1' : '1x1'),
          'borderStyle': post.border.border_style,
          'borderWidth': post.border.border_width,
        };
        return <LinkBox key={index} LinkBox={item}></LinkBox>
      }
    );
  }


  return (
    <React.Fragment>
      <a className={styles.title} href={news_page.permalink}>
        Blog
        <NewsIcon className={styles.titleIcon}/>
      </a>

      { text && (
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>
      )}

      <div className={styles.posts}>
        {blogPosts}
      </div>

      { (typeof link === 'undefined' || !link['url'] ) ? '' : (
        <div className={styles.loadMoreHolder}>
          <a
            href={link['url']}
            className={styles.loadMore}
          >
            <Plus className={styles.loadMoreIcon}/>
            {link['title']}
          </a>
        </div>
      )}
    </React.Fragment>
  );
};
export default News;
