import React from "react";
import Content from "../Content/Content";
import Container from "../Layout/Container";
import PageUpdater from "../PageUpdater/PageUpdater";
import Search from "../Search/Search";
import { useSelector } from "react-redux";
import styles from "./Page.module.scss";


const Page = ( props ) => {

  const page = useSelector(state => state.page.currentPage);
  let output;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchValue = urlParams.get('s') ?? false;

  if(searchValue) {
    output = <Search s={searchValue}/>;
  }
  else if(typeof page !== 'undefined' && page !== false) {
    output = <Content page={page}></Content>;
  }
  else if (typeof page !== 'undefined' && page === false) {
    output = (
      <div>
        <Container>
          <div className={styles.page404}>
            <div className={styles.title404}>{'404'}</div>
            <div className={styles.text404}>{'Die gesuchte Seite wurde leider nicht gefunden.'}</div>
          </div>
        </Container>
      </div>
    );
  }
  else {
    output = 'loading';
  }



  return (
    <div className={styles.mainContent}>
      <PageUpdater/>
      {output}
    </div>
  );
};
export default Page;
