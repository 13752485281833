import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: {},
};

const coursesSlice = createSlice( {
  name: 'courses',
  initialState: initialState,
  reducers: {
    addCourse(state, action) {
      state.courses[action.payload.id] = action.payload.course;
    },
  }
} );

export const coursesActions = coursesSlice.actions;
export default coursesSlice.reducer;
