import React from "react";

import styles from './Header.module.scss';
import {useSelector} from "react-redux";


const Menu = ( props ) => {
  const sstLink = useSelector( state => state.theme.options.sst_link );
  const sssLink = useSelector( state => state.theme.options.sss_link );
  const news_page = useSelector(state => state.theme.options.news_page);
  const courses_page = useSelector(state => state.theme.options.courses_page);

  return (
    <div className={styles.menu}>
      {sstLink && (
        <a href={sstLink} className={styles.menuItem}>Super Science Team</a>
      )}

      {sssLink && (
        <a href={sssLink} className={styles.menuItem}>Super Science Spaces</a>
      )}

      {typeof news_page !== 'undefined' && news_page.permalink && (
        <a href={news_page.permalink} className={styles.menuItem}>Blog</a>
      )}

      {typeof courses_page !== 'undefined' && courses_page.permalink && (
        <a href={courses_page.permalink} className={styles.menuItem}>Angebotsübersicht</a>
      )}
    </div>
  );
};

export default Menu;
