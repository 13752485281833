import React from "react";
import styles from './Description.module.scss';


const Description = ( props ) => {

  const text = props.attrs.data['block_description_text'];

  return (
    <div className={styles.text}
         dangerouslySetInnerHTML={{__html: text}} >
    </div>
  );

};
export default Description;
