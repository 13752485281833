import {REST_BASE, REST_COURSES, REST_INIT, REST_LAST_MODIFIED, REST_POSTS, REST_SEARCH} from "./constants";

export const getHiddenDimensions = ( e ) => {
  let hidden = [], style, elem = e;

  while( e.parentNode.tagName !== 'HTML' ) {
    style = e.currentStyle ? e.currentStyle : getComputedStyle( e, null );

    if( ( style.display === 'none' ) ) {
      hidden.push( {
        e: e,
        position: style.position,
        visibility: style.visibility,
        display: style.display
      } );

      e.style.setProperty( 'position', 'absolute', 'important' );
      e.style.setProperty( 'visibility', 'hidden', 'important' );
      e.style.setProperty( 'display', 'block', 'important' );
    }
    e = e.parentNode;
  }

  let results = elem.getBoundingClientRect();

  hidden.forEach( ( hiddenElement ) => {
    hiddenElement.e.removeAttribute( 'style' );
  } );
  return results;
};


export function hasPreview() {
  const isPreview = (window.location.toString()).includes('preview=true');
  const hasPreview = (window.location.toString()).includes('preview_id=');
  const hasPageId = (window.location.toString()).includes('page_id=');

  return (isPreview || hasPreview || hasPageId);
}


function getPreviewParam() {
  return hasPreview() ? '?preview=true' : '';
}



export const fetchCourse = async (id = '') => {
  // console.log("fetching new course");

  const response = await fetch( REST_BASE + REST_COURSES + id + getPreviewParam());
  if( !response.ok ) throw new Error('Failed fetching course data.');
  return await response.json();
};



export const fetchInit = async () => {
  console.log("fetching INIT");

  const response = await fetch( REST_BASE + REST_INIT + getPreviewParam());
  if( !response.ok ) throw new Error( 'Failed to fetch data.' );
  return await response.json();
};



export const fetchLastModified = async (ids) => {
  // console.log("fetching last modified");
  const response = await fetch( REST_BASE + REST_LAST_MODIFIED + '?ids=' + JSON.stringify(ids));
  if( !response.ok ) throw new Error( 'Failed to fetch last modified date.' );
  return await response.json();
};


export const fetchPosts = async (page = 1, selected_ids = '', ppp = 4) => {
  const response = await fetch( REST_BASE + REST_POSTS + '?page=' + page + selected_ids + '&cat=blog' + '&ppp=' + ppp.toString() );
  if( !response.ok ) throw new Error('Failed fetching posts data.');
  return await response.json();
};


export const fetchSearchResults = async (search, page = 1, per_page = 20) => {
  const response = await fetch(
    REST_SEARCH
    + '?page=' + page
    + '&per_page=' + per_page
    + '&search=' + search
  );
  if( !response.ok ) throw new Error('Failed fetching search data.');
  return await response.json();
};



export function orderByDate( a, b ) {
  let c = a.order_date !== 'not_defined' ? parseDate(a.order_date) : '';
  let d = b.order_date !== 'not_defined' ? parseDate(b.order_date) : '';
  return c-d;
}


export function orderByDateDesc( b, a ) {
  let c = a.order_date !== 'not_defined' ? parseDate(a.order_date) : '';
  let d = b.order_date !== 'not_defined' ? parseDate(b.order_date) : '';
  return c-d;
}


export function isPast( course ) {
  if( course.past_date === 'not_defined' ) return false;
  let today = new Date();
  return parseDate( course.past_date ) < today;
}


function parseDate(input) {
  var parts = input.match(/(\d+)/g);
  return new Date(parts[2], parts[1]-1, parts[0]);
}


export const getCookie = ( cname ) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent( document.cookie );
  let ca = decodedCookie.split( ';' );
  for( let i = 0; i < ca.length; i++ ) {
    let c = ca[ i ];
    while( c.charAt( 0 ) == ' ' ) {
      c = c.substring( 1 );
    }
    if( c.indexOf( name ) == 0 ) {
      return c.substring( name.length, c.length );
    }
  }
  return "";
};