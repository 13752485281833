// REST API BASE URL
export const REST_BASE = '/wp-json/sunlime/v1/';

// REST API ENDPOINTS
export const REST_INIT = 'init/';
export const REST_MENU = 'menus/';
export const REST_PAGES = 'pages/';
export const REST_POSTS = 'posts/';
export const REST_COURSES = 'courses/';
export const REST_LAST_MODIFIED = 'last-modified/';
export const REST_FORM = 'form/';

export const REST_SEARCH = '/wp-json/wp/v2/search/';

// REDUX EXPIRE
export const EXPIRE_SECONDS = 60 * 60 * 24