import React, {useId, useState, useEffect} from "react";
import classNames from "classnames/bind";
import { filterActions } from "../../stores/reducers/filter";
import styles from './Filter.module.scss';
import {useDispatch, useSelector} from "react-redux";
import Column from "../Layout/Column";
import Row from "../Layout/Row";
import Button from "../UI/Button";

import { ReactComponent as Search } from './../../assets/images/icons/SearchNew.svg';
import { ReactComponent as Chevron } from './../../assets/images/icons/ChevronRight.svg';
import { ReactComponent as Close } from './../../assets/images/icons/Close.svg';


const classes = classNames.bind( styles );


const Filter = ( props ) => {

  const dispatch = useDispatch();
  const filter = useSelector( state => state.filter );
  const current_page = useSelector( state => state.page.currentPage );
  const [ activeFilter, setActiveFilter ] = useState( Object.keys(filter.filter)[0] );
  let courseOverviewLink = '';
  const formID = useId();
  const courses_page = useSelector(state => state.theme.options.courses_page);

  useEffect( () => {
    const key = Object.keys(filter.filter)[0];
    if(key) {
      setActiveFilterHandler(key);
    }
  }, [filter.filter] );

  if(typeof courses_page !== 'undefined' && typeof current_page !== 'undefined') {
    if((courses_page && current_page && courses_page.id !== current_page.id) || activeFilter === 'search') {
      const onClick = activeFilter === 'search' ? ((e) => {
        e.preventDefault();
        document.getElementById(formID).submit();
      }) : null;
      courseOverviewLink = (
        <Button link={courses_page.permalink + '#course-overview'} rainbow onClick={onClick}>
          Ergebnisse anzeigen
          <Chevron />
        </Button>
      );
    }
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams( queryString );
  const [searchValue, setSearchValue] = useState(urlParams.get('s') ?? '')

  let isValid = Object.entries( filter.filter ).length > 0;

  if( !isValid ) {
    return '';
  }

  const entries = Object.entries( filter.filter ).map( ( [key, value] ) => {
    return (
      <Column width={3} key={ key } breakpoints={{ xs: 3 }}>
        <div data-active-count={filter.active['cat_'+key] ? filter.active['cat_'+key].length : 0}
             onClick={() => setActiveFilterHandler( key )}
             className={ classes({
               'filterEntry': true,
               'active': key === activeFilter,
               'hasValues': filter.active['cat_'+key] && filter.active['cat_'+key].length > 0
             })
             }>
          <div className={styles.filterEntryInner}>
            { value.title }
          </div>
        </div>
      </Column>
    );
  }).concat([(
    <Column width={3} key="search" breakpoints={{ xs: 3 }}>
      <div onClick={() => setActiveFilterHandler( "search" )}
           className={ classes({
             'filterEntry': true,
             'active': "search" === activeFilter,
             'search': true,
           })
           }>
        <div className={styles.filterEntryInner}>
          <Search className={styles.searchIcon} /> <span>Stichwort&shy;suche</span>
        </div>
      </div>
    </Column>
  )]);


  let subFilter = '';
  if(typeof filter.filter !== 'undefined') {
    subFilter = Object.entries( filter.filter ).map( ( [key, jvalue] ) => {
      let filterOptions = filter.filter[key].options.map( ( value, index ) => {
        return (
          <React.Fragment key={index}>
            <div key={index}
                 onClick={() => setFilterHandler( key, value.term_id )}
                 className={classes( {
                   'filterEntryBody': true,
                   'filterEntryFor': key === 'for' && value.name.includes('Schulstufe'),
                   'active': filter.active['cat_' + key] && filter.active['cat_' + key].includes( value.term_id )
                 } )
                 }>
              {value.name.replace( /&amp;/g, '&' ).replace( ' Schulstufe', '' )}
            </div>

            {key === 'for' && ( index === 3 || index === 7 ) ? <div className={styles.sepeartor}><div className={styles.sepeartorInner}></div></div> : ''}
          </React.Fragment>
        ); }
      );

      return (
        <div key={key} className={classes( {
          'subFilter': true,
          'isOpen': activeFilter === key,
          'center': key === 'where',
        })}>
          {key === 'for' ? <div className={styles.forTitle}>Schulstufe</div> : ''}
          {filterOptions}
        </div>
      );

    } ).concat([(
      <div key={'search'} className={classes( {
        'subFilter': true,
        'isOpen': activeFilter === "search",
        'search': true,
      } )}>
        <form action={'/'} className={styles.searchForm} id={formID}>
          <input type={'text'}
                 className={classes( {
                   'searchInput': true,
                 } )}
                 placeholder={'Suchbegriff eingeben'}
                 defaultValue={searchValue}
                 id={'s'}
                 name={'s'}
                 onChange={( event ) => setSearchValue( event.target.value )}
          />
        </form>
      </div>
    )] );
  }


  const setFilterHandler = ( taxonomy, termID ) => {
    dispatch( filterActions.setFilter( {
      taxonomy: 'cat_' + taxonomy,
      termID: termID
    } ) );
  };

  const resetFilterHandler = () => {
    dispatch( filterActions.reset() );
  };

  const setActiveFilterHandler = ( key ) => {
    setActiveFilter( key === activeFilter ? activeFilter : key );
  };

  const activeFilters = Object.values( filter.active ).reduce((a, b) => a + b.length, 0);
  const actions = activeFilters === 0 ? '' :
    (
      <div className={styles.actions}>
        <div className={styles.activeFilters}>
          {activeFilters} Filter aktiv
        </div>
        <div className={styles.actionsButtons}>
          <Button rainbow onClick={ resetFilterHandler }>
            Filter zurücksetzen
            <Close className={styles.closeIcon} />
          </Button>
          {courseOverviewLink}
        </div>
      </div>
    )
  ;

  return (
    <div className={classes( {
      'filter': true,
      "hasOpenFilter": activeFilter,
      "is-open": props.showFilter,
      'searchOpen': activeFilter === "search"
    } )}>
      <Row className={styles.inner}>
        {entries}

        <div className={classes( {
          'filterEntryBodyInner': true,
          'filterEntryBodyMobile': true
        } )}>
          {subFilter}
          {actions}
        </div>
      </Row>

      <div className={classes( {
        'filterEntryBodyInner': true,
        'filterEntryBodyDesktop': true
      } )}>
        {subFilter}
        {actions}
      </div>

    </div>
  );
};
export default Filter;
