import React from "react";
import styles from './Checkbox.module.scss';
import classNames from "classnames/bind";
import parse from "html-react-parser";
const classes = classNames.bind( styles );

const Checkbox = ( props ) => {

  const key = props.field.form_key;
  const required = props.field.form_required;
  let label = props.field.form_label + (required ? '*' : '');
  label = !props.field.isTerms ? label : props.field.form_label;

  return (
    <div className={classes( {
        'oCheckbox': true,
        'terms': props.field.isTerms
    }
    )}>
      <input type={'checkbox'}
             id={key}
             name={key}
             required={required}
             className={styles.oCheckboxField}/>
      <label className={styles.oCheckboxLabel} htmlFor={key}>
        {parse(label)}
      </label>
    </div>
  );
};
export default Checkbox;
