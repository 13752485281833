
import image_0 from '../../assets/images/animations/img_0.png';
import image_1 from '../../assets/images/animations/img_1.png';
import image_2 from '../../assets/images/animations/img_2.png';
import image_3 from '../../assets/images/animations/img_3.png';
import image_4 from '../../assets/images/animations/img_4.png';
import image_5 from '../../assets/images/animations/img_5.png';
import image_6 from '../../assets/images/animations/img_6.png';
import image_7 from '../../assets/images/animations/img_7.png';
import image_8 from '../../assets/images/animations/img_8.png';
import image_9 from '../../assets/images/animations/img_9.png';
import image_10 from '../../assets/images/animations/img_10.png';

const animationData = {
  "v": "5.7.4",
  "fr": 25,
  "ip": 38,
  "op": 132,
  "w": 1920,
  "h": 1080,
  "nm": "Roboter 1 bewegung 3",
  "ddd": 0,
  "assets": [{ "id": "image_0", "w": 303, "h": 156, "u": "", "p": image_0, "e": 0 }, {
    "id": "image_1",
    "w": 256,
    "h": 119,
    "u": "",
    "p": image_1,
    "e": 0
  }, { "id": "image_2", "w": 284, "h": 127, "u": "", "p": image_2, "e": 0 }, {
    "id": "image_3",
    "w": 256,
    "h": 118,
    "u": "",
    "p": image_3,
    "e": 0
  }, { "id": "image_4", "w": 350, "h": 156, "u": "", "p": image_4, "e": 0 }, {
    "id": "image_5",
    "w": 256,
    "h": 147,
    "u": "",
    "p": image_5,
    "e": 0
  }, { "id": "image_6", "w": 211, "h": 138, "u": "", "p": image_6, "e": 0 }, {
    "id": "image_7",
    "w": 485,
    "h": 394,
    "u": "",
    "p": image_7,
    "e": 0
  }, { "id": "image_8", "w": 441, "h": 402, "u": "", "p": image_8, "e": 0 }, {
    "id": "image_9",
    "w": 170,
    "h": 204,
    "u": "",
    "p": image_9,
    "e": 0
  }, { "id": "image_10", "w": 170, "h": 204, "u": "", "p": image_10, "e": 0 }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 2,
    "nm": "Emoji zwinkern/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_0",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [266.221, 220.951, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [151.5, 78, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 165,
    "op": 264,
    "st": 165,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 2,
    "nm": "Emoji traurig/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_1",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [244.239, 230.99, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [128, 59.5, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 134,
    "op": 165,
    "st": 132,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 3,
    "ty": 2,
    "nm": "Emoji Love/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_2",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [244.239, 230.99, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [142, 63.5, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 99,
    "op": 134,
    "st": 99,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 4,
    "ty": 2,
    "nm": "Emoji lachend/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_3",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [244.239, 230.99, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [128, 59, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 66,
    "op": 99,
    "st": 66,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 5,
    "ty": 2,
    "nm": "Emoji lachend 2/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_4",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [244.207, 212.99, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [175, 78, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 33,
    "op": 66,
    "st": 33,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 6,
    "ty": 2,
    "nm": "Emoji erstaunt/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_5",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [240.232, 226.997, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [128, 73.5, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 33,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 7,
    "ty": 2,
    "nm": "Hut/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 8,
    "refId": "image_6",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": {
        "a": 1,
        "k": [{
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 28,
          "s": [-0.101]
        }, {
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 40,
          "s": [-39.101]
        }, {
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 59,
          "s": [-39.101]
        }, {
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 71,
          "s": [-0.101]
        }, {
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 93,
          "s": [-0.101]
        }, {
          "i": { "x": [0.38], "y": [1] },
          "o": { "x": [0.62], "y": [0] },
          "t": 105,
          "s": [-39.101]
        }, { "i": { "x": [0.38], "y": [1] }, "o": { "x": [0.62], "y": [0] }, "t": 128, "s": [-39.101] }, {
          "t": 140,
          "s": [-0.101]
        }],
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 28,
          "s": [393.762, -39.275, 0],
          "to": [-186.734, -35.6, 0],
          "ti": [112.598, -41.269, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 40,
          "s": [65.762, -26.275, 0],
          "to": [-51.368, 18.827, 0],
          "ti": [-112.598, 41.269, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 59,
          "s": [65.762, -26.275, 0],
          "to": [112.598, -41.269, 0],
          "ti": [-186.734, -35.6, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 71,
          "s": [393.762, -39.275, 0],
          "to": [186.734, 35.6, 0],
          "ti": [53.742, 10.246, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 93,
          "s": [393.762, -39.275, 0],
          "to": [-186.734, -35.6, 0],
          "ti": [112.598, -41.269, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 105,
          "s": [65.762, -26.275, 0],
          "to": [-51.368, 18.827, 0],
          "ti": [-112.598, 41.269, 0]
        }, {
          "i": { "x": 0.38, "y": 1 },
          "o": { "x": 0.62, "y": 0 },
          "t": 128,
          "s": [65.762, -26.275, 0],
          "to": [112.598, -41.269, 0],
          "ti": [-186.734, -35.6, 0]
        }, { "t": 140, "s": [393.762, -39.275, 0] }],
        "ix": 2,
        "l": 2
      },
      "a": { "a": 0, "k": [105.5, 69, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 8,
    "ty": 2,
    "nm": "Kopf/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 11,
    "refId": "image_7",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": {
        "a": 1,
        "k": [{
          "i": { "x": [0.57], "y": [1] },
          "o": { "x": [0.43], "y": [0] },
          "t": 38,
          "s": [8]
        }, { "i": { "x": [0.57], "y": [1] }, "o": { "x": [0.43], "y": [0] }, "t": 66, "s": [0] }, {
          "i": {
            "x": [0.57],
            "y": [1]
          }, "o": { "x": [0.43], "y": [0] }, "t": 99, "s": [14]
        }, { "t": 131, "s": [8] }],
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": { "x": 0.57, "y": 1 },
          "o": { "x": 0.43, "y": 0 },
          "t": 38,
          "s": [245.949, -41.854, 0],
          "to": [-3.167, -2.667, 0],
          "ti": [-0.667, 0.333, 0]
        }, {
          "i": { "x": 0.57, "y": 1 },
          "o": { "x": 0.43, "y": 0 },
          "t": 66,
          "s": [226.949, -57.854, 0],
          "to": [0.667, -0.333, 0],
          "ti": [-3.167, -2.667, 0]
        }, {
          "i": { "x": 0.57, "y": 1 },
          "o": { "x": 0.43, "y": 0 },
          "t": 99,
          "s": [249.949, -43.854, 0],
          "to": [3.167, 2.667, 0],
          "ti": [0.667, -0.333, 0]
        }, { "t": 131, "s": [245.949, -41.854, 0] }],
        "ix": 2,
        "l": 2
      },
      "a": { "a": 0, "k": [236.5, 379, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 9,
    "ty": 4,
    "nm": "Shape Layer 1",
    "parent": 8,
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -0.101, "ix": 10 },
      "p": { "a": 0, "k": [62.134, 432.261, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[66, -2], [22, -40], [-4, -26], [-30, -10], [-30, -2], [-26, 10], [-4, 24], [8, 28], [24, 10]],
            "o": [[-66, 2], [-22, 40], [4, 26], [30, 10], [30, 2], [26, -10], [4, -24], [-8, -28], [-24, -10]],
            "v": [[188, -416], [-20, -350], [-48, -174], [10, -84], [176, -48], [336, -80], [408, -164], [402, -310], [336, -390]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "st",
        "c": { "a": 0, "k": [0.996078012504, 1, 0, 1], "ix": 3 },
        "o": { "a": 0, "k": 100, "ix": 4 },
        "w": { "a": 0, "k": 0, "ix": 5 },
        "lc": 1,
        "lj": 1,
        "ml": 4,
        "bm": 0,
        "nm": "Stroke 1",
        "mn": "ADBE Vector Graphic - Stroke",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [0, 0], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Shape 1",
      "np": 3,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0, "ind": 10, "ty": 4, "nm": "Fahne", "parent": 13, "sr": 1, "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": {
        "a": 1,
        "k": [{
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 10,
          "s": [27.878]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 19,
          "s": [30.072]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 29,
          "s": [1.438]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 38,
          "s": [26.878]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 67,
          "s": [16.878]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 83,
          "s": [36.878]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 101,
          "s": [20.878]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.47], "y": [0] },
          "t": 131,
          "s": [26.878]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 175,
          "s": [2]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 188,
          "s": [4.545]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 226,
          "s": [10.878]
        }, { "t": 255, "s": [2.878] }],
        "ix": 10
      },
      "p": { "a": 0, "k": [112.245, 48.755, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [924.205, 583.83, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [412, 412, 100], "ix": 6, "l": 2 }
    }, "ao": 0, "shapes": [{
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [-0.28, 0.785], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [-0.464, -0.691], [0, 0], [0, 0]],
            "v": [[-43.61, -40.767], [-38.627, -0.874], [42.937, -11.061], [31.115, -28.652], [30.822, -30.999], [37.954, -50.955]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ind": 1,
        "ty": "sh",
        "ix": 2,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0.108, 0], [0.166, 1.328], [0, 0], [0.002, 0.013], [0, 0], [0.001, 0.013], [0, 0], [-1.438, 0.18], [-0.18, -1.439], [0, 0], [0, 0], [-0.57, -0.711], [0.307, -0.858], [0, 0], [0, 0], [0.378, -0.83], [0.904, -0.113], [0, 0], [0, 0], [1.438, -0.18]],
            "o": [[-1.304, 0], [0, 0], [-0.001, -0.013], [0, 0], [-0.001, -0.013], [0, 0], [-0.179, -1.438], [1.436, -0.183], [0, 0], [0, 0], [0.906, -0.11], [0.569, 0.711], [0, 0], [0, 0], [0.508, 0.756], [-0.378, 0.83], [0, 0], [0, 0], [0.179, 1.438], [-0.11, 0.014]],
            "v": [[-34.411, 56.712], [-37.012, 54.412], [-43.506, 2.418], [-43.511, 2.381], [-49.144, -42.721], [-49.149, -42.759], [-50.099, -50.366], [-47.819, -53.296], [-44.89, -51.016], [-44.26, -45.976], [41.536, -56.693], [43.91, -55.73], [44.333, -53.205], [36.21, -30.48], [49.673, -10.45], [49.883, -7.898], [47.82, -6.381], [-37.977, 4.335], [-31.803, 53.762], [-34.082, 56.692]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 2",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [0.898039221764, 0.113725490868, 0.298039227724, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [960.027, 540.136], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Group 1",
      "np": 3,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }, {
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
            "v": [[-1.596, 2.052], [2.052, 1.596], [1.596, -2.052], [-2.052, -1.596]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [0.898039221764, 0.113725490868, 0.298039227724, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [984.165, 520.359], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Group 2",
      "np": 2,
      "cix": 2,
      "bm": 0,
      "ix": 2,
      "mn": "ADBE Vector Group",
      "hd": false
    }, {
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "v": [[-1.772, -1.598], [-1.682, -0.879], [0.174, -1.111], [-1.503, 1.223], [-1.408, 1.977], [1.773, 1.58], [1.681, 0.859], [-0.288, 1.105], [1.354, -1.215], [1.259, -1.977]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [979.979, 520.834], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Group 3",
      "np": 2,
      "cix": 2,
      "bm": 0,
      "ix": 3,
      "mn": "ADBE Vector Group",
      "hd": false
    }, {
      "ty": "gr", "it": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [-0.423, 0.053], [-0.132, -1.052], [0, 0], [-0.042, -0.261], [-0.058, -0.274], [0, 0], [0.021, 0.11], [0, 0], [0.453, -0.057], [0.085, 0.678], [-0.356, 0.221], [-0.417, 0.052], [0, 0], [0.588, -0.073], [0.305, -0.275]],
              "o": [[0.366, -0.215], [1.166, -0.146], [0, 0], [0.044, 0.358], [0.041, 0.269], [0, 0], [-0.059, -0.182], [0, 0], [-0.18, 0.424], [-0.675, 0.084], [-0.066, -0.533], [0.358, -0.22], [0, 0], [-0.071, -0.575], [-0.423, 0.053], [0, 0]],
              "v": [[-1.667, -1.447], [-0.37, -1.883], [1.467, -0.586], [1.524, -0.129], [1.651, 0.76], [1.794, 1.551], [0.917, 1.661], [0.807, 1.132], [0.791, 1.134], [-0.313, 1.899], [-1.783, 1.007], [-1.256, -0.092], [0.005, -0.46], [0.553, -0.528], [-0.405, -1.19], [-1.537, -0.656]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Path 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ind": 1,
          "ty": "sh",
          "ix": 2,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [-0.132, 0.212], [0.034, 0.277], [0, 0], [-0.069, -0.545], [-0.358, 0.044]],
              "o": [[0.305, -0.038], [0.138, -0.222], [0, 0], [-0.445, 0.056], [0.038, 0.305], [0, 0]],
              "v": [[-0.152, 1.19], [0.507, 0.785], [0.623, 0.025], [0.192, 0.078], [-0.838, 0.832], [-0.152, 1.19]],
              "c": false
            },
            "ix": 2
          },
          "nm": "Path 2",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "fl",
          "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
          "o": { "a": 0, "k": 100, "ix": 5 },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": { "a": 0, "k": [976.1, 521.345], "ix": 2 },
          "a": { "a": 0, "k": [0, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100], "ix": 3 },
          "r": { "a": 0, "k": 0, "ix": 6 },
          "o": { "a": 0, "k": 100, "ix": 7 },
          "sk": { "a": 0, "k": 0, "ix": 4 },
          "sa": { "a": 0, "k": 0, "ix": 5 },
          "nm": "Transform"
        }],
        "nm": "Group 1",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }, {
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0, 0], [0, 0], [0, 0], [-0.594, 0.074], [-0.107, -0.014], [0, 0], [0.178, -0.023], [-0.064, -0.512], [0, 0], [0, 0]],
              "o": [[0, 0], [0, 0], [0, 0], [0.004, -0.339], [0.098, -0.013], [0, 0], [-0.095, -0.039], [-0.808, 0.101], [0, 0], [0, 0], [0, 0]],
              "v": [[-1.18, -1.611], [-0.297, -1.721], [-0.196, -0.912], [-0.18, -0.913], [0.755, -1.937], [1.064, -1.948], [1.18, -1.012], [0.725, -1.032], [0.098, 0.52], [0.261, 1.829], [-0.736, 1.954]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Path 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "fl",
          "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
          "o": { "a": 0, "k": 100, "ix": 5 },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": { "a": 0, "k": [972.552, 521.701], "ix": 2 },
          "a": { "a": 0, "k": [0, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100], "ix": 3 },
          "r": { "a": 0, "k": 0, "ix": 6 },
          "o": { "a": 0, "k": 100, "ix": 7 },
          "sk": { "a": 0, "k": 0, "ix": 4 },
          "sa": { "a": 0, "k": 0, "ix": 5 },
          "nm": "Transform"
        }],
        "nm": "Group 2",
        "np": 2,
        "cix": 2,
        "bm": 0,
        "ix": 2,
        "mn": "ADBE Vector Group",
        "hd": false
      }, {
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0.416, -0.052], [-0.123, -0.989], [-1.106, 0.138], [-0.172, 0.063], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.557, -0.07], [0.204, 1.632], [-1.761, 0.22], [-0.462, -0.068]],
              "o": [[-0.423, -0.123], [-1.21, 0.151], [0.123, 0.984], [0.281, -0.035], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.533, 0.207], [-1.68, 0.21], [-0.207, -1.655], [0.601, -0.075], [0, 0]],
              "v": [[1.722, -1.658], [0.352, -1.746], [-1.309, 0.238], [0.668, 1.741], [1.343, 1.587], [1.186, 0.328], [0.086, 0.466], [-0.009, -0.296], [2.086, -0.558], [2.411, 2.044], [0.763, 2.503], [-2.384, 0.444], [0.139, -2.493], [1.687, -2.484]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Path 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "fl",
          "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
          "o": { "a": 0, "k": 100, "ix": 5 },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": { "a": 0, "k": [968.361, 521.572], "ix": 2 },
          "a": { "a": 0, "k": [0, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100], "ix": 3 },
          "r": { "a": 0, "k": 0, "ix": 6 },
          "o": { "a": 0, "k": 100, "ix": 7 },
          "sk": { "a": 0, "k": 0, "ix": 4 },
          "sa": { "a": 0, "k": 0, "ix": 5 },
          "nm": "Transform"
        }],
        "nm": "Group 3",
        "np": 2,
        "cix": 2,
        "bm": 0,
        "ix": 3,
        "mn": "ADBE Vector Group",
        "hd": false
      }, {
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0.17, -0.559], [0.362, -0.422], [0.535, -0.27], [0.657, -0.082], [0.584, 0.131], [0.459, 0.319], [0.297, 0.501], [0.082, 0.658], [0, 0], [0, 0], [0, 0], [-0.123, -0.282], [-0.231, -0.222], [-0.35, -0.117], [-0.486, 0.06], [-0.309, 0.2], [-0.168, 0.271], [-0.05, 0.302], [0.033, 0.267], [0, 0], [0, 0]],
              "o": [[0.082, 0.658], [-0.17, 0.559], [-0.363, 0.421], [-0.534, 0.271], [-0.655, 0.081], [-0.585, -0.13], [-0.461, -0.319], [-0.298, -0.5], [0, 0], [0, 0], [0, 0], [0.034, 0.267], [0.122, 0.281], [0.231, 0.222], [0.348, 0.118], [0.486, -0.061], [0.308, -0.2], [0.169, -0.272], [0.05, -0.304], [0, 0], [0, 0], [0, 0]],
              "v": [[5.01, 1], [4.878, 2.826], [4.078, 4.297], [2.733, 5.333], [0.946, 5.862], [-0.914, 5.789], [-2.481, 5.116], [-3.619, 3.887], [-4.187, 2.149], [-5.05, -4.76], [-3.025, -5.013], [-2.171, 1.826], [-1.937, 2.65], [-1.407, 3.405], [-0.537, 3.914], [0.714, 4], [1.907, 3.609], [2.623, 2.902], [2.951, 2.04], [2.976, 1.183], [2.122, -5.656], [4.147, -5.909]],
              "c": true
            },
            "ix": 2
          },
          "nm": "Path 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "fl",
          "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
          "o": { "a": 0, "k": 100, "ix": 5 },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": { "a": 0, "k": [975.77, 511.974], "ix": 2 },
          "a": { "a": 0, "k": [0, 0], "ix": 1 },
          "s": { "a": 0, "k": [100, 100], "ix": 3 },
          "r": { "a": 0, "k": 0, "ix": 6 },
          "o": { "a": 0, "k": 100, "ix": 7 },
          "sk": { "a": 0, "k": 0, "ix": 4 },
          "sa": { "a": 0, "k": 0, "ix": 5 },
          "nm": "Transform"
        }],
        "nm": "Group 4",
        "np": 2,
        "cix": 2,
        "bm": 0,
        "ix": 4,
        "mn": "ADBE Vector Group",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [975.77, 511.974], "ix": 2 },
        "a": { "a": 0, "k": [975.77, 511.974], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }], "nm": "Group 4", "np": 4, "cix": 2, "bm": 0, "ix": 4, "mn": "ADBE Vector Group", "hd": false
    }, {
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "v": [[-0.902, -3.364], [-4.409, -2.926], [-4.628, -4.679], [4.408, -5.807], [4.628, -4.055], [1.122, -3.617], [2.268, 5.554], [0.243, 5.807]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [0.113725490868, 0.113725490868, 0.105882354081, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [965.348, 513.14], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Group 5",
      "np": 2,
      "cix": 2,
      "bm": 0,
      "ix": 5,
      "mn": "ADBE Vector Group",
      "hd": false
    }, {
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            "v": [[-0.272, -11.087], [0.412, -5.611], [-6.16, -4.79], [-5.476, 0.685], [-6.571, 0.822], [-7.254, -4.653], [-18.204, -3.286], [-16.836, 7.664], [-11.362, 6.98], [-10.678, 12.456], [0.273, 11.089], [-0.411, 5.612], [6.159, 4.792], [5.475, -0.684], [6.571, -0.82], [7.254, 4.655], [18.204, 3.287], [16.836, -7.663], [11.362, -6.978], [10.678, -12.455]],
            "c": true
          },
          "ix": 2
        },
        "nm": "Path 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ty": "fl",
        "c": { "a": 0, "k": [0.898039221764, 0.113725490868, 0.298039227724, 1], "ix": 4 },
        "o": { "a": 0, "k": 100, "ix": 5 },
        "r": 1,
        "bm": 0,
        "nm": "Fill 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": { "a": 0, "k": [942.787, 516.258], "ix": 2 },
        "a": { "a": 0, "k": [0, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100], "ix": 3 },
        "r": { "a": 0, "k": 0, "ix": 6 },
        "o": { "a": 0, "k": 100, "ix": 7 },
        "sk": { "a": 0, "k": 0, "ix": 4 },
        "sa": { "a": 0, "k": 0, "ix": 5 },
        "nm": "Transform"
      }],
      "nm": "Group 6",
      "np": 2,
      "cix": 2,
      "bm": 0,
      "ix": 6,
      "mn": "ADBE Vector Group",
      "hd": false
    }], "ip": 0, "op": 15000, "st": 0, "bm": 0
  }, {
    "ddd": 0,
    "ind": 11,
    "ty": 2,
    "nm": "Koerper/TuTor1 für Animation.ai",
    "cl": "ai",
    "refId": "image_8",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": -9.849, "ix": 10 },
      "p": {
        "a": 1,
        "k": [{
          "i": { "x": 0.67, "y": 1 },
          "o": { "x": 0.33, "y": 0 },
          "t": 38,
          "s": [724.197, 810.231, 0],
          "to": [28.667, 0, 0],
          "ti": [-10.333, 2.667, 0]
        }, {
          "i": { "x": 0.67, "y": 0.766 },
          "o": { "x": 0.33, "y": 0 },
          "t": 66,
          "s": [896.197, 810.231, 0],
          "to": [10.333, -2.667, 0],
          "ti": [28.667, 0, 0]
        }, {
          "i": { "x": 0.67, "y": 1 },
          "o": { "x": 0.33, "y": 0.408 },
          "t": 99,
          "s": [786.197, 794.231, 0],
          "to": [-28.667, 0, 0],
          "ti": [10.333, -2.667, 0]
        }, { "t": 131, "s": [724.197, 810.231, 0] }],
        "ix": 2,
        "l": 2
      },
      "a": { "a": 0, "k": [214.5, 171, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 12,
    "ty": 2,
    "nm": "Arm links/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 11,
    "refId": "image_9",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": {
        "a": 1,
        "k": [{
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 38,
          "s": [23.771]
        }, {
          "i": { "x": [0.53], "y": [1] },
          "o": { "x": [0.47], "y": [0] },
          "t": 64,
          "s": [40.771]
        }, { "i": { "x": [0.53], "y": [1] }, "o": { "x": [0.47], "y": [0] }, "t": 94, "s": [39.159] }, {
          "t": 131,
          "s": [23.771]
        }],
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": { "x": 0.53, "y": 1 },
          "o": { "x": 0.47, "y": 0 },
          "t": 38,
          "s": [-18.267, 50.014, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": { "x": 0.53, "y": 1 },
          "o": { "x": 0.47, "y": 0 },
          "t": 64,
          "s": [-16.267, 13.014, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": { "x": 0.53, "y": 1 },
          "o": { "x": 0.47, "y": 0 },
          "t": 94,
          "s": [-34.163, 77.582, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, { "t": 131, "s": [-18.267, 50.014, 0] }],
        "ix": 2,
        "l": 2
      },
      "a": { "a": 0, "k": [141, 46, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 13,
    "ty": 2,
    "nm": "Arm rechts/TuTor1 für Animation.ai",
    "cl": "ai",
    "parent": 11,
    "refId": "image_10",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": {
        "a": 1,
        "k": [{
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 38,
          "s": [-8.592]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 62,
          "s": [-20.592]
        }, {
          "i": { "x": [0.833], "y": [0.833] },
          "o": { "x": [0.167], "y": [0.167] },
          "t": 108,
          "s": [-7.592]
        }, { "t": 131, "s": [-8.592] }],
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": { "x": 0.833, "y": 0.833 },
          "o": { "x": 0.167, "y": 0.167 },
          "t": 38,
          "s": [468.144, 47.152, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": { "x": 0.833, "y": 0.833 },
          "o": { "x": 0.167, "y": 0.167 },
          "t": 62,
          "s": [492.144, -16.848, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": { "x": 0.833, "y": 0.833 },
          "o": { "x": 0.167, "y": 0.167 },
          "t": 108,
          "s": [477.145, 122.814, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, { "t": 131, "s": [468.144, 47.152, 0] }],
        "ix": 2,
        "l": 2
      },
      "a": { "a": 0, "k": [23, 48, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 14,
    "ty": 1,
    "nm": "White Solid 1",
    "sr": 1,
    "ks": {
      "o": { "a": 0, "k": 100, "ix": 11 },
      "r": { "a": 0, "k": 0, "ix": 10 },
      "p": { "a": 0, "k": [960, 540, 0], "ix": 2, "l": 2 },
      "a": { "a": 0, "k": [960, 540, 0], "ix": 1, "l": 2 },
      "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
    },
    "ao": 0,
    "sw": 1920,
    "sh": 1080,
    "sc": "transparent",
    "ip": 0,
    "op": 15000,
    "st": 0,
    "bm": 0
  }],
  "markers": [{ "tm": 0, "cm": "anim", "dr": 195 }]
};

export default animationData;