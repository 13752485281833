import React from "react";
import Container from "../Layout/Container";
import BlogOverview from "../BlogOverview/BlogOverview";
import Title from "../Title/Title";
import Description from "../Description/Description";
import News from "../News/News";
import MediaText from "../MediaText/MediaText";
import LinkBoxes from "../LinkBoxes/LinkBoxes";
import Post from "../Post/Post";
import Course from "../Course/Course";
import Text from "../Text/Text";
import Author from "../Author/Author";
import CourseOverview from "../CourseOverview/CourseOverview";
import CourseOverviewGrid from "../CourseOverviewGrid/CourseOverviewGrid";
import Slider from "../Slider/Slider";
import Form from "../Form/Form";


const Content = ( props ) => {

  let blocks = props.courseContent ? props.blocks : (props.page ? props.page.blocks : []);
  let content = [];

  for ( const [ key, block ] of Object.entries( blocks ) ) {
    let blockContent;
    let blockName   = block['blockName'].replace( 'acf/', '' );
    let isFullWidth = block['attrs']['data'][`block_layout_${blockName}_container`] === 'full';

    let paddingTop    = 'padding-top-' + block['attrs']['data'][`block_layout_${blockName}_padding_top`];
    let paddingBottom = 'padding-bottom-' + block['attrs']['data'][`block_layout_${blockName}_padding_bottom`];
    let paddingTopMobile    = 'padding-top-m-' + block['attrs']['data']['block_layout_'+blockName+'_padding_top_mobile'];
    let paddingBottomMobile = 'padding-bottom-m-' + block['attrs']['data']['block_layout_'+blockName+'_padding_bottom_mobile'];
    let anchor        = block['attrs']['data']['block_layout_'+blockName+'_anchor'];
    let border        = block['attrs']['data']['block_layout_'+blockName+'_border'];

    switch( blockName ) {
      case 'text':
        blockContent = <Text attrs={block['attrs']}></Text>;
        break;
      case 'description':
        blockContent = <Description attrs={block['attrs']}></Description>;
        break;
      case 'blog-overview':
        blockContent = <BlogOverview attrs={block['attrs']}></BlogOverview>;
        break;
      case 'title':
        blockContent = <Title attrs={block['attrs']}></Title>;
        break;
      case 'link-boxes':
        blockContent = <LinkBoxes attrs={block['attrs']}></LinkBoxes>;
        break;
      case 'news':
        blockContent = <News attrs={block['attrs']}></News>;
        break;
      case 'media-text':
        blockContent = <MediaText attrs={block['attrs']}></MediaText>;
        break;
      case 'courses-overview':
        if(block['attrs'].data['block_courses-overview_layout'] === 'list') {
          blockContent = <CourseOverview attrs={block['attrs']}></CourseOverview>;
        }
        else {
          blockContent = <CourseOverviewGrid attrs={block['attrs']}></CourseOverviewGrid>;
        }
        break;
      case 'author':
        blockContent = <Author author={props.page.post_type === 'post' ? props.page.author : block['attrs'] }></Author>;
        break;
      case 'slider':
        blockContent = <Slider attrs={block['attrs']}></Slider>;
        break;
      case 'form':
        blockContent = <Form attrs={block['attrs']}></Form>;
        break;
      default:
        blockContent = <p key={key}>unrecognized block "{block['blockName']}"</p>;
    }

    content.push(
      <Container key={key}
                 paddingTop={paddingTop}
                 paddingBottom={paddingBottom}
                 paddingTopMobile={paddingTopMobile}
                 paddingBottomMobile={paddingBottomMobile}
                 anchor={anchor}
                 fullWidth={isFullWidth}
                 border={border}
      >
        {blockContent}
      </Container>);
  }


  if(props.page && props.page.post_type === 'course' && !props.courseContent) {
    return <Course id={props.page.id}>{content}</Course>
  }
  else if(props.page && props.page.post_type === 'post' && !props.courseContent) {
    return <Post post={props.page}>{content}</Post>
  }
  else {
    return <div>{content}</div>
  }


};
export default Content;
