import React, {useState, useEffect} from "react";

import styles from './../CourseOverview/CourseOverview.module.scss';
import {fetchCourse, isPast, orderByDate, orderByDateDesc} from "../../util/helpers";
import {coursesActions} from "../../stores/reducers/courses";
import {useDispatch, useSelector} from "react-redux";
import ListItem from "./ListItem";
import classNames from "classnames/bind";

import { ReactComponent as Close } from './../../assets/images/icons/Close.svg';
import {filterActions} from "../../stores/reducers/filter";

const classes = classNames.bind( styles );


const CourseOverview = ( props ) => {

  const [courses, setCourses] = useState(undefined);
  const dispatch = useDispatch();
  const storedCourses = useSelector(state => state.courses.courses);
  const filter = useSelector( state => state.filter.filter );
  const activeFilter = useSelector( state => state.filter.active );

  const filter_for   = props.attrs.data['block_courses-overview_for'] ?? [];
  const filter_where = props.attrs.data['block_courses-overview_where'] ?? [];
  const filter_when  = props.attrs.data['block_courses-overview_when'] ?? [];
  const filter_topic = props.attrs.data['block_courses-overview_topic'] ?? [];
  const filter_organization = props.attrs.data['block_courses-overview_organization'] ?? [];

  const additional = props.attrs.data['block_courses-overview_additional_courses'] ?? [];

  const usingFilter = filter_for.length === 0 && filter_when.length === 0 && filter_where.length === 0 && filter_topic.length === 0 && filter_organization.length === 0;

  useEffect( () => {
    if(typeof courses === 'undefined') {
      fetchCourse().then( data => {

        let courses = [];
        let past_courses = [];

        for(let i = 0; i < data.courses.length; i++) {
          switch( data.courses[i].infos.date ) {
            case 'from_to':
              data.courses[i]['past_date'] = data.courses[i].infos.date_to ?? 'not_defined';
              data.courses[i]['order_date'] = data.courses[i].infos.date_from ?? 'not_defined';
              data.courses[i]['date'] = data.courses[i].infos.date_from + ' - ' + data.courses[i].infos.date_to ;
              break;
            case 'single':
              data.courses[i]['past_date'] = data.courses[i].infos.date_single ?? 'not_defined';
              data.courses[i]['order_date'] = data.courses[i].infos.date_single ?? 'not_defined';
              data.courses[i]['date'] = data.courses[i].infos.date_single ?? data.courses[i].infos.date_text;
              break;
            default:
              data.courses[i]['past_date'] = 'not_defined';
              data.courses[i]['order_date'] = 'not_defined';
              data.courses[i]['date'] = data.courses[i].infos.date_text ?? '-';
          }

          if(isPast(data.courses[i])) {
            data.courses[i]['is_past'] = true;
            past_courses.push(data.courses[i]);
          } else {
            data.courses[i]['is_past'] = false;
            courses.push(data.courses[i]);
          }

          dispatch(coursesActions.addCourse({
            'id': data.courses[i].id,
            'course': data.courses[i]
          }));
        }

        courses.sort( orderByDate );
        past_courses.sort( orderByDateDesc );
        courses = courses.concat(past_courses);
        setCourses(courses);

      } ).catch( error => {
        console.log(error)
      } );
    }
  }, [setCourses, courses, dispatch, storedCourses]);



  let courseList = '';
  let courseTable = '';
  let selection = [];

  if(typeof courses !== 'undefined' && courses.length > 0) {
    courseList = courses.filter(function(course) {

      let showCourse = true;

      if( additional.length > 0 && additional.includes( `${course.id}` ) ) {
        return true;
      }

      // IF it is the overview used for the filter
      if(usingFilter) {
        if(activeFilter.cat_for && activeFilter.cat_for.length > 0 && course.categories['for']) {
          showCourse = course.categories['for'].some(term => {
            return activeFilter.cat_for.includes(term.term_id);
          });
        }
        if(showCourse && activeFilter.cat_where && activeFilter.cat_where.length > 0 && course.categories['where']) {
          showCourse = course.categories['where'].some(term => {
            return activeFilter.cat_where.includes(term.term_id);
          });
        }
        if(showCourse && activeFilter.cat_when && activeFilter.cat_when.length > 0 && course.categories['when']) {
          showCourse = course.categories['when'].some(term => {
            return activeFilter.cat_when.includes(term.term_id);
          });
        }
      }
      // otherwise, the filter is retrieved by the element itself
      else {
        showCourse = false;
        if(filter_for.length > 0 && course.categories['for']) {
          showCourse = course.categories['for'].some(term => {
            return filter_for.includes(term.term_id.toString());
          });
        }
        if(filter_where.length > 0 && course.categories['where']) {
          showCourse = course.categories['where'].some(term => {
            return filter_where.includes(term.term_id.toString());
          });
        }
        if(filter_when.length > 0 && course.categories['when']) {
          showCourse = course.categories['when'].some(term => {
            return filter_when.includes(term.term_id.toString());
          });
        }
        if(filter_topic.length > 0 && course.categories['topic']) {
          showCourse = course.categories['topic'].some(term => {
            return filter_topic.includes(term.term_id.toString());
          });
        }
        if(filter_organization.length > 0 && course.categories['organization']) {
          showCourse = course.categories['organization'].some(term => {
            return filter_organization.includes(term.term_id.toString());
          });
        }
      }

      return showCourse;

    }).map(function(course, index) {
      let item = {
        'title':    course.title,
        'image':    course.thumbnail,
        'link':     course.permalink,
        'title_bg': false,
        'icon':     false,
        'layout':   index % 6 === 1 || index % 6 === 3 ? '2x2' : '2x1',
        'borderStyle': course.border.border_style,
        'borderWidth': course.border.border_width,
        'isCourse': true,
        'course': course.id,
        'is_past': course.is_past,
        'sold_out': course.sold_out,
      };
      return <ListItem key={index} item={item} />
    });



    if(usingFilter) {
      if(activeFilter.cat_for) {
        for(let i = 0; i < activeFilter.cat_for.length; i++) {
          if(activeFilter.cat_for[i]) {
            let filterOption = filter['for'].options.filter((option) => { return option.term_id === activeFilter.cat_for[i] })
            selection.push(filterOption[0]);
          }
        }
      }
      if(activeFilter.cat_where) {
        for(let i = 0; i < activeFilter.cat_where.length; i++) {
          if(activeFilter.cat_where[i]) {
            let filterOption = filter['where'].options.filter((option) => { return option.term_id === activeFilter.cat_where[i] })
            selection.push(filterOption[0]);
          }
        }
      }
      if(activeFilter.cat_when) {
        for(let i = 0; i < activeFilter.cat_when.length; i++) {
          if(activeFilter.cat_when[i]) {
            let filterOption = filter['when'].options.filter((option) => { return option.term_id === activeFilter.cat_when[i] })
            selection.push(filterOption[0]);
          }
        }
      }
    }


    if( courseList.length === 0) {
      courseTable = (<div className={styles.noResults}>Es wurden keine Ergebnisse gefunden.</div>) ;
    }
    else {
      courseTable = (
        <React.Fragment>

          {selection.length > 0 && (
            <>
              <div className={styles.selectedTitle}>Aktive Filter:</div>
              <div className={styles.selectedFilter}>
                {selection.map((filterOption, index) => {
                  return (
                    <div key={index} className={classes( {
                      selectedFilterOption: true,
                      for: filterOption.taxonomy === "for_category",
                      where: filterOption.taxonomy === "where_category",
                      when: filterOption.taxonomy === "when_category",
                    } )} onClick={() => {
                      dispatch( filterActions.setFilter( {
                        taxonomy: 'cat_' + (filterOption.taxonomy.replace('_category', '')),
                        termID: filterOption.term_id
                      } ) );
                    }}>
                      {filterOption.name}
                      <Close className={styles.closeIcon} />
                    </div>
                  );
                } )}
              </div>
            </>
          )}

          <div className={styles.courseList}>
            {courseList}
          </div>
        </React.Fragment>
      );
    }
  }

  return (
    <div id={'course-overview'}>
      {courseTable}
    </div>
  );
};
export default CourseOverview;
