import React, {useLayoutEffect, useState} from "react";

import { ReactComponent as TuLogo } from './../../assets/images/tuLogo.svg';
import { ReactComponent as Background } from './../../assets/images/header/background.svg';
import { ReactComponent as SSTLogo } from './../../assets/images/header/SSTLogo.svg';

import Container from "../Layout/Container";
import styles from './Header.module.scss';
import {useSelector} from "react-redux";

import Lottie from "lottie-react";
import animationData from "./Animation";
import Menu from "./Menu";
import FilterButton from "./FilterButton";
import classNames from "classnames/bind";

const classes = classNames.bind( styles );


const Header = ( props ) => {

  const themeOptions = useSelector( state => state.theme.options );
  const [init, setInit] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const tuLink = themeOptions ? themeOptions.tu_link : '';

  useLayoutEffect(() => {
    if(init) {
      let href = window.top.location.hash.substr(1);
      if(href) {
        setTimeout(() => {
          let element = document.getElementById(href);
          if(element) {
            element.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 500);
      }
    } else {
      setInit(false);
    }
  }, [init])


  const openMobileMenu = () => {
    if(isOpen) {
      setIsOpen(false);
      setIsOpening(true);
      setTimeout(() => {
        setIsOpening(false);
      }, 100)
    }
    else {
      setIsOpening(true);
      setTimeout(() => {
        setIsOpen(!isOpen);
        setIsOpening(false);
      }, 500)
    }
  }


  return (
    <header className={styles.header}>
      <Container>
        <div className={styles.headerTop}>
          <Background className={styles.background}/>
          <div className={styles.headerTopInner}>
            <a className={styles.logoContainerLeft} href={"/"}>
              <SSTLogo className={styles.textLogo}/>
              <Lottie className={styles.astronaut} animationData={animationData} loop={true}/>
            </a>

            <div className={styles.logoContainerRight}>
              <a href={tuLink} target={'_blank'} rel={'noreferrer'}>
                <TuLogo className={styles.tuLogo}/>
              </a>
            </div>
          </div>
        </div>

        <div className={styles.title}>
          DAS KINDER- & JUGENDPORTAL DER TU GRAZ

          <div className={classes({
            'burger': true,
            'is-open': isOpen
          })} onClick={openMobileMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={classes({
          'menuHolder': true,
          'is-opening': isOpening,
          'is-open': isOpen
        })}>
          <Menu/>
          <FilterButton/>
        </div>

      </Container>
    </header>
  );
};

export default Header;
