import React from "react";
import Container from "../Layout/Container";
import Row from "../Layout/Row";
import Column from "../Layout/Column";

import styles from './Footer.module.scss';

import {useSelector} from "react-redux";

const Footer = ( props ) => {
  const themeOptions = useSelector( state => state.theme.options );

  if(!themeOptions) return '';

  return (
    <footer className={styles.footer}>
      <Container>
        <Row>
          <Column className={['wysiwyg', styles.footerLeft]} width={6} breakpoints={{m: 12}}>
            { themeOptions.footer_right ? <div dangerouslySetInnerHTML={{__html: themeOptions.footer_left}}></div> : '' }
          </Column>

          <Column className={['wysiwyg', styles.footerRight]} width={6} breakpoints={{m: 12}}>
            { themeOptions.footer_right ? <div dangerouslySetInnerHTML={{__html: themeOptions.footer_right}}></div> : '' }
          </Column>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
