import React, {useState, useEffect} from "react";
import styles from './Course.module.scss';
import Row from "../Layout/Row";
import Column from "../Layout/Column";
import Container from "../Layout/Container";

import {ReactComponent as IconFor} from "../../assets/images/icons/course/play_blue.svg";
import {ReactComponent as IconCosts} from "../../assets/images/icons/course/money.svg";
import {ReactComponent as IconRegistration} from "../../assets/images/icons/course/in_out.svg";
import {ReactComponent as IconContact} from "../../assets/images/icons/course/email.svg";
import {ReactComponent as IconDates} from "../../assets/images/icons/course/play_green.svg";
import {ReactComponent as IconLocation} from "../../assets/images/icons/course/play_red.svg";
import {ReactComponent as IconMoreInfos} from "../../assets/images/icons/course/question.svg";
import {ReactComponent as IconInfos} from "../../assets/images/icons/course/info.svg";

import {fetchCourse} from "../../util/helpers";
import {useDispatch, useSelector} from "react-redux";
import {coursesActions} from "../../stores/reducers/courses";
import Content from "../Content/Content";


const Course = ( props ) => {

  const [ course, setCourse ] = useState( undefined );
  const courses = useSelector(state => state.courses.courses);
  const dispatch = useDispatch();

  useEffect( () => {
    if(typeof course === 'undefined') {
      // if(courses && props.id in courses && !hasPreview()) {
      //   setCourse(courses[props.id]);
      // }
      // else {
        fetchCourse(props.id).then( data => {
          setCourse(data);
          dispatch(coursesActions.addCourse({
            'id': props.id,
            'course': data
          }));
        } ).catch( error => {
          console.log(error)
        } );
      }
    // }
  }, [setCourse, course, courses, dispatch, props.id]);

  const infoTitles = {
    'for_text': "FÜR?",
    'location': "WO?",
    'date_single': "WANN?",
    'date_from': "Termin von",
    'date_to': "Termin bis",
    'costs': "Kursbeitrag",
    'registration': "Anmeldung",
    'more_infos': "Weitere Informationen",
    'contact': "Kontakt",
  };

  const infoIcons = {
    'for_text':    <IconFor className={styles.icon}/>,
    'costs':       <IconCosts className={styles.icon}/>,
    'date_single': <IconDates className={styles.icon}/>,
    'date_from':   <IconDates className={styles.icon}/>,
    'date_to':     <IconDates className={styles.icon}/>,
    'location':    <IconLocation className={styles.icon}/>,
    'registration':<IconRegistration className={styles.icon}/>,
    'more_infos':  <IconMoreInfos className={styles.icon}/>,
    'contact':     <IconContact className={styles.icon}/>,
  };


  let infos = [];
  if(typeof course !== 'undefined' && !course.hide_infos) {

    if(typeof course.categories.for[0] !== 'undefined' && (!course.infos.for_text || course.infos.for_text === ''))  {
      const forItems = course.categories.for.map((item, i) => {
        return (i === 0 ? '' : '<br>') + item.name;
      })
      infos.push(
        <div className={styles.infoContent} key={'for'}>
          <IconFor className={styles.icon}/>
          <div className={styles.infoHeading}>
            <div className={styles.infoSubTitle}>{ 'Für?'}</div>
            <div className={styles.infoValue} dangerouslySetInnerHTML={{__html: forItems}}></div>
          </div>
        </div>
      );
    }

    // eslint-disable-next-line
    infos.push(Object.entries( course.infos ).map( ( [key, info] ) => {
        if(info && info !== '') {
          let search_key = key;
          let search_info = info;
          if(key === 'date' && info === 'not_defined') {
            search_key = 'date_single';
            search_info = course.infos.date_text;
          }

          return search_key in infoTitles ? (
            <div className={styles.infoContent} key={key}>
              { search_key in infoIcons ? infoIcons[search_key] : ''}
              <div className={styles.infoHeading}>
                <div className={styles.infoSubTitle}>{ search_key in infoTitles ? infoTitles[search_key] : ''}</div>
                <div className={styles.infoValue} dangerouslySetInnerHTML={{__html: search_info}}></div>
              </div>
            </div>
          ) : '';
        }
      }
    ));
  }



  if(typeof course === 'undefined') return '';

  return (
    <div className={styles.course}>
      <Container>
        <Row>
          {
            course.hide_infos ? '' : (
              <Column width={4} breakpoints={({ 'm': 12 })}>
                <div className={styles.infos}>
                <div className={styles.infosInner}>
                  <div className={styles.infoTitleHolder}>
                    <IconInfos className={styles.infoIcon} />
                    <div className={styles.infoTitle}>
                      <div>{"Information:"}</div>
                      {course.title}
                    </div>
                  </div>
                  {infos}
                </div>
                </div>
              </Column>
            )
          }

          <Column width={course.hide_infos ? 12 : 8} breakpoints={( { 'm': 12 } )}>
            <div className={styles.imageHolder}>
              {!course.thumbnail ? '' : (
                <img src={course.thumbnail['sizes']['full']} className={styles.image} alt={course.thumbnail.alt}
                     title={course.thumbnail.title}/>
              )}
            </div>
            <div className={styles.title}>
              {course.title}
            </div>
            <div className={styles.content}>
              {course.blocks ? <Content blocks={course.blocks} courseContent={true}/> : props.children}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );

};
export default Course;
