import React, {useState, useEffect} from "react";

import styles from './Form.module.scss';
import Row from "../Layout/Row";
import Column from "../Layout/Column";
import Container from "../Layout/Container";
import Textfield from "./Fields/Textfield/Textfield";
import Checkbox from "./Fields/Checkbox/Checkbox";
import Textarea from "./Fields/Textarea/Textarea";
import Radio from "./Fields/Radio/Radio";
import Select from "./Fields/Select/Select";
import {REST_BASE, REST_FORM} from "../../util/constants";
import classNames from "classnames/bind";
import Text from "../Text/Text";
import Upload from "./Fields/Upload/Upload";
import CheckboxGroup from "./Fields/CheckboxGroup/CheckboxGroup";
import parse from "html-react-parser";
import Button from "../UI/Button";
import { ReactComponent as Chevron } from './../../assets/images/icons/ChevronRight.svg';

const classes = classNames.bind( styles );

const Form = ( props ) => {

  const areas = props.attrs['form-areas'];
  const terms = props.attrs['form-terms'];
  const form_id = props.attrs.data['block_form_form'];
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const formAreas = areas.map((area, index) => {

    const areaTitle = area['form_area_title'];
    let areaIcons = !area['form_area_icons'] ? [] : area['form_area_icons'].map((icon, index) => {
      return (
        <div className={classes({
          'icon': true,
          'topLeft': icon.form_position === 'top_left',
          'topRight': icon.form_position === 'top_right',
          'bottomLeft': icon.form_position === 'bottom_left',
          'bottomRight': icon.form_position === 'bottom_right',
        })} key={index}>
          <img src={icon.form_icon['sizes']['medium']} alt={icon.form_icon['alt']} title={icon.form_icon['title']} className={styles.iconImage}/>
        </div>
      );
    });


    const areaFields = area['form_form_fields'].map((field, index) => {
      let fieldContent = '';

      switch( field.acf_fc_layout ) {
        case 'form_textfield':
          fieldContent = <Textfield field={field} type={"text"}/>;
          break;
        case 'form_date':
          fieldContent = <Textfield field={field} type={"date"}/>;
          break;
        case 'form_mail':
          fieldContent = <Textfield field={field} type={"email"}/>;
          break;
        case 'form_checkbox':
          fieldContent = <Checkbox field={field}/>;
          break;
        case 'form_textarea':
          fieldContent = <Textarea field={field}/>;
          break;
        case 'form_radio':
          fieldContent = <Radio field={field}/>;
          break;
        case 'form_phone':
          fieldContent = <Textfield field={field} type={"phone"}/>;
          break;
        case 'form_text_block':
          fieldContent = <Text attrs={{data: { block_text_text: field.form_text } }}/>;
          break;
        case 'form_dropdown':
          fieldContent = <Select field={field}/>;
          break;
        case 'form_upload':
          fieldContent = <Upload field={field}/>;
          break;
        case 'form_checkbox_group':
          fieldContent = <CheckboxGroup field={field}/>;
          break;
        default:
          fieldContent = field.acf_fc_layout;
      }

      return (
        <Column width={12} key={index}>
          {fieldContent}
        </Column>
      );
    });

    return (
      <div className={styles.area} key={index}>
        <div className={styles.areaTitle}>
          {areaTitle}
        </div>
        <Row>
          {areaFields}
        </Row>
        {areaIcons}
      </div>
    );
  });

  const termsCheckbox = !terms ? '' : <div>
    <Checkbox field={ {
      form_key: 'AGB',
      form_required: true,
      form_label: <div dangerouslySetInnerHTML={{__html: terms}} ></div>,
      isTerms: true
    } }/>
  </div>;


  const submitForm = function(e) {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setSuccess(false);

    const sendForm = async () => {
      let form = document.querySelector('#form_' + form_id);
      let formData = new FormData(form);

      let response = await fetch( REST_BASE + REST_FORM, {
        method: 'POST',
        body: formData
      });
      if(!response.ok) {
        throw new Error('Failed to post form!');
      }
      return response.json();
    };

    sendForm().then((data) => {
      if(data.success) {
        setLoading(false);
        setMessage(data.message);
        setSuccess(true);
        document.getElementById('form_' + form_id).reset();
      }
      else {
        setMessage(data.message);
        setLoading(false);
        for(let i = 0; i < data.wrong_fields.length; i++) {
          let elem = document.getElementsByName(data.wrong_fields[i].name)[0];
          if(!elem) {
            elem = document.getElementsByName(data.wrong_fields[i].name + '[]');
            for (let i = 0; i < elem.length; i++) {
              elem[i].parentNode.classList.add(styles.hasError);
            }
          }
          else if(elem.getAttribute('type') === 'radio') {
            elem.parentNode.parentNode.classList.add(styles.hasError);
          }
          else {
            elem.parentNode.classList.add(styles.hasError);
          }
        }
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }




  useEffect(() => {
    let classname = document.querySelectorAll('#form_' + form_id + ' input');

    let removeError = function() {
      this.parentNode.classList.remove(styles.hasError);

      if(this.getAttribute('type') === 'radio') {
        this.parentNode.parentNode.classList.remove(styles.hasError);
      }
      else {
        let checkboxes = document.getElementsByName(this.getAttribute('name'));
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].parentNode.classList.remove(styles.hasError);
        }
      }
    };

    for (let i = 0; i < classname.length; i++) {
      classname[i].addEventListener('keyup', removeError, false);
      classname[i].addEventListener('change', removeError, false);
    }
  })



  return (
    <Container>
      <form id={'form_' + form_id} className={classes({
        'form': true,
        'isLoading': loading,
        'isSuccess': success,
      })}>
        <input type={'hidden'} value={form_id} name={'form_id'} />
        {formAreas}

        {termsCheckbox}

        <div className={styles.submitHolder}>
          <Button rainbow onClick={submitForm} >
            Anfrage absenden
            <Chevron />
          </Button>
        </div>

        <div className={classes( {
          'message': true,
          'success': success
        })}>
          {parse(message)}
        </div>

      </form>
    </Container>
  );
};
export default Form;
