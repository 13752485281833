import React from "react";
import styles from './Author.module.scss';


const Author = ( props ) => {

  let author = false;

  if( props.author.data && props.author.data['block_author_author'] ) {
    author = JSON.parse(props.author.data['block_author_author']);
  }
  else if( props.author && !props.author.data ) {
    author = props.author;
  }

  if(!author) return '';

  return (
    <div className={styles.author}>
      {!author.image ? '' : (
        <img src={author.image.sizes['full']} alt={author.image.alt} title={author.image.title} className={styles.image} />
      )}
      <div className={styles.infos}>
        <div className={styles.preTitle}>Autor:in</div>
        <div className={styles.name}>{author.name}</div>
        <div className={styles.date}>{author.date}</div>
        <div className={styles.text}
             dangerouslySetInnerHTML={{__html: author.text}}></div>
        {
          author.mail ? <a href={'mailto:'+author.mail} className={styles.mail}>E-Mail</a> : ''
        }
      </div>
    </div>
);

};
export default Author;
