import React from "react";

import styles from './Button.module.scss';
import classNames from "classnames/bind";
const classes = classNames.bind( styles );


const Button = ( props ) => {
  return (
    <a href={props.link ||'#'}
       className={classes({
         'button': true,
         'rainbow': props.rainbow
       })}
       onClick={props.onClick || null}
    >
      {props.children}
    </a>
  );
};

export default Button;
