import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Import CSS/SCSS
import './sass/settings/_base.css';
import './sass/base/_normalize.css';
import './sass/base/_elements.css';
import './sass/typography/_typography.css';
import './sass/components/_components.css';


// Import Redux
import { Provider } from "react-redux";
import store from "./stores/store";
import { persistStore } from "redux-persist";
import { PersistGate} from "redux-persist/integration/react";
import {getCookie} from "./util/helpers";

let persistor = persistStore( store );
// persistor.purge();

let has_session = window.sessionStorage.getItem("sst_session");

if(has_session !== "true") {
  persistor.purge();
  window.sessionStorage.setItem("sst_session", "true");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
