import React from "react";
import classNames from "classnames/bind";

import styles from './Row.module.scss';
const classes = classNames.bind(styles);

const Row = ( props ) => {
  const justify = props.justify ? props.justify.toString().toLowerCase() : '';
  const align = props.align ? props.align.toString().toLowerCase() : '';

  return <div className={classes( props.className, {
    'row': true,
    [`align--${align}`]: true,
    [`justify--${justify}`]: true,
    'spacing--small': props.spacing === 'small'
  })}>{props.children}</div>;
};
export default Row;
