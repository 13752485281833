import React, {useEffect, useState} from "react";
import Container from "../Layout/Container";
import {fetchSearchResults} from "../../util/helpers";
import styles from './Search.module.scss';
import parse from "html-react-parser";

const Search = ( props ) => {

  const s = props.s;
  const [results, setResults] = useState(undefined);
  let resultsList = '';

  useEffect( () => {
    if(typeof results === 'undefined') {
      fetchSearchResults(s).then( data => {
        setResults(data);
      } ).catch( error => {
        console.log(error)
      } );
    }
  }, [results, setResults, s]);


  if(typeof results !== 'undefined') {
    if( results.length === 0 ) {
      resultsList = 'Es wurden leider keine Ergebnisse gefunden.';
    }
    else {
      resultsList = results.map((result) => {
        return (
          <a key={result.title}
             href={result.url}
             className={styles.result}
          >
            {parse(result.title)}
          </a>
        )
      })
    }
  }


  return (
    <Container>
      <h1>Deine Suche:</h1>
      <div className={styles.searchValue}>{s}</div>

      <div className={styles.results}>
        {resultsList}
      </div>
    </Container>
  );
};
export default Search;
