import React from "react";
import styles from './Post.module.scss';
import borderStyles from '../../sass/base/_border.scss';
import Container from "../Layout/Container";
import classNames from "classnames/bind";
import {useSelector} from "react-redux";
const classes = classNames.bind( styles, borderStyles );


const Post = ( props ) => {

  const post = props.post;
  const news_page = useSelector(state => state.theme.options.news_page);

  if(typeof post === 'undefined') return 'loading';

  return (
    <div className={styles.post}>
      <Container>
        <div className={classes(
          {
            'imageHolder': true,
            'imageHolderDashed': post.border.border_style === 'dashed',

            'borderRed': true,
            'borderThin': post.border.border_width === 'thin',
            'borderNormal': post.border.border_width === 'normal' || typeof post.border.border_width === 'undefined',
            'borderThick': post.border.border_width === 'thick',
            'borderSolid': post.border.border_style !== 'dashed',
            'borderDashed': post.border.border_style === 'dashed',
          }
        )}>
          {!post.thumbnail ? '' : (
            <img src={post.thumbnail['sizes']['post-title']}
                 className={classes({
                   'image': true,
                   'dashedImage': post.border.border_style === 'dashed',
                 })}
                 alt={post.thumbnail.alt} title={post.thumbnail.title} />
          )}
        </div>

        <div className={styles.preTitle}>
          <a href={news_page.permalink} className={styles.blogLink}>Blog</a>
        </div>
        <div className={styles.title}>
          {post.title}
        </div>


      </Container>

      {props.children}
    </div>
  );
};
export default Post;
