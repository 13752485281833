import React from "react";

import styles from './Title.module.scss';
import classNames from "classnames/bind";
const classes = classNames.bind( styles );

const Title = ( props ) => {

  const title_text = props.attrs.data['block_title_title'];
  const title_tag  = props.attrs.data['block_title_title_tag'];
  const title_styletag = 'like-' + props.attrs.data['block_title_title_styletag'];

  const subtitle_text = props.attrs.data['block_title_subtitle'];
  const subtitle_tag  = props.attrs.data['block_title_subtitle_tag'];
  const subtitle_styletag = 'like-' + props.attrs.data['block_title_subtitle_styletag'];

  const color = props.attrs.data['block_title_color'];
  const align = props.attrs.data['block_title_align'];

  let title = '';
  let subtitle = '';

  switch( title_tag ) {
    case 'h1':
      title = <h1 className={title_styletag}>{title_text}</h1>
      break;
    case 'h2':
      title = <h1 className={title_styletag}>{title_text}</h1>
      break;
    case 'h3':
      title = <h1 className={title_styletag}>{title_text}</h1>
      break;
    case 'h4':
      title = <h1 className={title_styletag}>{title_text}</h1>
      break;
    case 'h5':
      title = <h1 className={title_styletag}>{title_text}</h1>
      break;
    default:
      title = <div className={title_styletag}>{title_text}</div>
  }


  switch( subtitle_tag ) {
    case 'h1':
      subtitle = <h1 className={subtitle_styletag}>{subtitle_text}</h1>
      break;
    case 'h2':
      subtitle = <h1 className={subtitle_styletag}>{subtitle_text}</h1>
      break;
    case 'h3':
      subtitle = <h1 className={subtitle_styletag}>{subtitle_text}</h1>
      break;
    case 'h4':
      subtitle = <h1 className={subtitle_styletag}>{subtitle_text}</h1>
      break;
    case 'h5':
      subtitle = <h1 className={subtitle_styletag}>{subtitle_text}</h1>
      break;
    default:
      subtitle = <div className={subtitle_styletag}>{subtitle_text}</div>
  }

  return (
    <div className={classes({
      "titleHolder": true,
      "isRed": color === 'red',
    }, 'align'+align)}>

      {title}

      {subtitle}

    </div>
  );
};
export default Title;
