import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filter: [],
  active: {}
};

const filterSlice = createSlice( {
  name: 'filter',
  initialState: initialState,
  reducers: {
    fillFilter( state, action ) {
      state.filter = action.payload;
    },

    setFilter( state, action ) {
      if( typeof state.active[action.payload.taxonomy] === 'undefined' ) {
        state.active[action.payload.taxonomy] = [];
      }

      const index = state.active[action.payload.taxonomy].indexOf( action.payload.termID );
      let newActiveArray = state.active[action.payload.taxonomy];

      if( index !== -1 ) {
        newActiveArray.splice(index, 1);
      } else {
        newActiveArray.push( action.payload.termID );
      }
      state.active[action.payload.taxonomy] = newActiveArray;
    },

    reset( state, action ) {
      state.active = {};
    }
  }
} );

export const filterActions = filterSlice.actions;
export default filterSlice.reducer;
