import React from "react";
import styles from './Textarea.module.scss';


const Textarea = ( props ) => {

  const placeholder = props.field.form_placeholder;
  const key = props.field.form_key;
  const required = props.field.form_required;
  const label = props.field.form_label + (required ? '*' : '');

  return (
    <div className={styles.oTextarea}>
      <textarea id={key}
                name={key}
                required={required}
                placeholder={placeholder}
                className={styles.oTextareaField} >
      </textarea>
      <label className={styles.oTextareaLabel}>{label}</label>
    </div>
  );

};
export default Textarea;
