import React, {useEffect, useState} from "react";

import styles from './LinkBoxes.module.scss';
import borderStyles from '../../sass/base/_border.scss';
import classNames from "classnames/bind";
import {fetchCourse} from "../../util/helpers";

import {ReactComponent as IconFor} from "../../assets/images/icons/Alter10.svg";
import {ReactComponent as IconWhere} from "../../assets/images/icons/Ort.svg";
import {ReactComponent as IconWhen} from "../../assets/images/icons/Kalender.svg";
import {ReactComponent as Play} from "../../assets/images/icons/Play.svg";
import {useDispatch, useSelector} from "react-redux";
import {coursesActions} from "../../stores/reducers/courses";
import parse from "html-react-parser";

const classes = classNames.bind( styles, borderStyles );


const LinkBox = ( props ) => {

  const item = props.LinkBox;
  const [title, setTitle] = useState(undefined);
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [icon, setIcon] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [courseContent, setCourseContent] = useState('');
  const courses = useSelector(state => state.courses.courses);
  const dispatch = useDispatch();

  let imageSize = item.layout === '2x1' ? 'linkbox-wide' : (item.layout === '2x2' ? 'linkbox-large' : 'linkbox');
  const bgColor = item.title_bg_color && item.title_bg_color !== '---' ? {
    background: `var(--color-${item.title_bg_color})`
  } : {};

  useEffect( () => {
    if(typeof title === 'undefined') {
      if(item.isCourse) {
        if(courses && (item.course in courses)) {
          setTitle(courses[item.course].title);
          if(courses[item.course].thumbnail) {
            setImage(<img className={styles.image} src={courses[item.course].thumbnail.sizes[imageSize]} alt={courses[item.course].thumbnail.alt} title={courses[item.course].thumbnail.title} />);
          }
          setLink(courses[item.course].permalink);
          setCourseContent(
            <div className={styles.courseInner}>
              <div className={styles.courseTitle}>
                {courses[item.course].title}
              </div>
              <div className={styles.courseContent} key={"courseContent"}>
                {
                  !courses[item.course].categories.for[0] ? '' :
                    <div className={styles.infoContent}>
                      <IconFor className={styles.infoIcon}/>
                      <div className={styles.infoHeading}> {courses[item.course].categories.for[0].name} </div>
                    </div>
                }
                {
                  !courses[item.course].categories.where[0] ? '' :
                    <div className={styles.infoContent}>
                      <IconWhere className={styles.infoIcon}/>
                      <div className={styles.infoHeading}> {courses[item.course].categories.where[0].name} </div>
                    </div>
                }
                {
                  !courses[item.course].categories.when[0] ? '' :
                    <div className={styles.infoContent}>
                      <IconWhen className={styles.infoIcon}/>
                      <div className={styles.infoHeading}> {courses[item.course].categories.when[0].name} </div>
                    </div>
                }

              </div>
              <div className={styles.courseBtn} key={"courseBtn"}>
                mehr informationen
              </div>
            </div>
          );
        }
        else {
          fetchCourse(item.course).then( course => {
            dispatch(coursesActions.addCourse({
              'id': item.course,
              'course': course
            }));
            setTitle(course.title);
            setImage(<img className={styles.image} src={course.thumbnail.sizes[imageSize]} alt={course.thumbnail.alt} title={course.thumbnail.title} />);
            setLink(course.permalink);
            setCourseContent(
              <div className={styles.courseInner}>
                <div className={styles.courseTitle}>
                  {course.title}
                </div>
                <div className={styles.courseContent} key={"courseContent"}>
                  {
                    !course.categories.for[0] ? '' :
                      <div className={styles.infoContent}>
                        <IconFor className={styles.infoIcon}/>
                        <div className={styles.infoHeading}> {course.categories.for[0].name} </div>
                      </div>
                  }
                  {
                    !course.categories.where[0] ? '' :
                      <div className={styles.infoContent}>
                        <IconWhere className={styles.infoIcon}/>
                        <div className={styles.infoHeading}> {course.categories.where[0].name} </div>
                      </div>
                  }
                  {
                    !course.categories.when[0] ? '' :
                      <div className={styles.infoContent}>
                        <IconWhen className={styles.infoIcon}/>
                        <div className={styles.infoHeading}> {course.categories.when[0].name} </div>
                      </div>
                  }

                </div>
                <div className={styles.courseBtn} key={"courseBtn"}>
                  mehr informationen
                </div>
              </div>
            );
          } ).catch( error => {
            console.log(error)
          } );
        }
      }
      else {
        if(item.type === 'video') {
          setImage(
            <React.Fragment>
              <video className={styles.video} >
                <source src={item.video.url} type="video/mp4"/>
              </video>
              <div className={styles.videoOverlay}>
                <Play className={styles.videoPlay}/>
              </div>
            </React.Fragment>
          );
        }
        else {
          setLink(item.link['url'] ?? '');
          if(item.image) {
            setImage(<img className={styles.image} src={item.image.sizes[imageSize]} alt={item.image.alt} title={item.image.title} />);
          }
          setTitle(item.title);
          setIcon(item.icon ? (
            <img src={item.icon['sizes'][imageSize]} alt={'icon'} className={styles.icon} />
          ) : '');
        }
      }
    }
  }, [title, item.isCourse, item.course, item.link, item.image, item.title, item.icon, courses, dispatch, item.type, item.video]);


  const playVideo = (e) => {
    e.preventDefault();
    if(isPlaying) {
      setIsPlaying(false);
      e.target.parentNode.querySelector('video').pause();
    }
    else {
      setIsPlaying(true);
      e.target.parentNode.querySelector('video').play();
    }
  }

  const linkImage = () => {}

  return (
    <a href={link} className={classes({
      'linkBox': true,
      'squareSmall': item.layout === '1x1',
      'squareBig':   item.layout === '2x2',
      'rectangle':   item.layout === '2x1',
      'course': item.isCourse,
    })}
       onClick={item.type === 'video' ? playVideo : linkImage}
    >
      <div className={styles.linkBoxInner} key={"linkboxinner"}>
        <div className={
          classes({
            'imageHolder': true,
            'isPlaying': isPlaying
          })}>
          {image}
        </div>

        <div className={classes({
              'title': true,
              'hasBackground': !item.title_bg,
              'isVideo': item.type === "video"
            })}
             style={bgColor}
        >
          {title ? parse(title) : ''}
        </div>

        {courseContent}
        {icon}
      </div>
    </a>
  );
};
export default LinkBox;
